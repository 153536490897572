<!-- 报修列表 rms_repairs_list.vue-->
<template>
  <el-form ref="searchForm"   label-width="240" :inline="true">
    <el-form-item ><el-button   @click="addition()">新增报修</el-button></el-form-item>
    <el-form-item ><el-button   @click="assign()">指派工单</el-button></el-form-item>
    <el-form-item ><el-button   @click="cancel()">取消工单</el-button></el-form-item>
    <!-- <el-form-item ><el-button   @click="querygrade()">等级评级</el-button></el-form-item> -->
    <el-form-item ><el-button   @click="exportExcel()">导出Excel</el-button></el-form-item>
	<div class="clear"></div>
    <el-form-item label="选择项目:" prop="name">
    <el-select v-model="article" placeholder="请选择" style="width:240px" clearable>
      <el-option v-for="data in optionsype" :key="data.plot_id" :label="data.plot_name" :value="data.plot_id"></el-option>
    </el-select>
    </el-form-item>
    <el-form-item label="报修状态:" prop="status">
      <el-select v-model="status" placeholder="请选择" style="width:240px" clearable>
        <el-option  v-for="data in statuss" :key="data.value" :label="data.label" :value="data.value"></el-option>
      </el-select>
    </el-form-item>
	
	<el-form-item label="状态:" prop="is_del_info">
	  <el-select v-model="is_del_info" placeholder="请选择" style="width:240px" clearable>
	    <el-option  v-for="data in is_dels" :key="data.value" :label="data.label" :value="data.value"></el-option>
	  </el-select>
	</el-form-item>
	
	<el-form-item label="工单类型:" prop="order_type">
	  <el-select v-model="order_type" placeholder="请选择" style="width:240px" clearable>
	    <el-option  v-for="data in order_types" :key="data.value" :label="data.label" :value="data.value"></el-option>
	  </el-select>
	</el-form-item>
	
    <el-form-item label="时间查询:">
      <el-date-picker
          v-model="selecttime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="YYYY-MM-DD"
      >
      </el-date-picker>
    </el-form-item>
    <el-form-item label="综合查询:" prop="name"><el-input  v-model="tableDataName" placeholder="清输入" style="width:240px" clearable></el-input></el-form-item>
	 <el-form-item label="报修ID:" prop="name"><el-input  v-model="bsid" placeholder="清输入" style="width:240px" clearable></el-input></el-form-item>
    <el-form-item><el-button type="primary" @click="queryrepairs()" icon="el-icon-search" >搜索</el-button></el-form-item>
    <el-form-item><el-button type="danger" @click="reset()" plain>重置</el-button></el-form-item>
  </el-form>
  <el-table :data="dataList" border  ref="multipleTable"  @selection-change="handleSelectionChange" :row-style="TableRowStyle">
    <el-table-column  type="selection"  align="center"  width="50px"></el-table-column>
    <el-table-column prop="id" label="ID"  width="80px" fixed></el-table-column>
    <!-- <el-table-column prop="user_name" label="报修用户" ></el-table-column>      //repair_username -->
    <el-table-column prop="real_name" label="真实姓名"  :formatter="forstusta"></el-table-column>
    <el-table-column prop="father_name" label="报修类型" ></el-table-column>
    <el-table-column prop="plot_name" label="所属项目"  width="88"></el-table-column>
    <el-table-column prop="mode" label="报修模式" :formatter="formode" width="88"></el-table-column>
    <el-table-column prop="content" label="报修详情" :show-overflow-tooltip="true"></el-table-column>
	<el-table-column prop="address" label="报修地点" :show-overflow-tooltip="true" width="170px"></el-table-column>
    <el-table-column prop="order_type" label="工单类型" :formatter="forordertype" ></el-table-column>
	<el-table-column prop="create_time" label="报修时间" :formatter="setCreateTime" width="160"></el-table-column>
    <el-table-column prop="status" label="报修状态" :formatter="forstatus">
		<template v-slot="scope">
			 <el-button  type="grey" v-if="scope.row.status==1">{{ forstatus(scope.row)}}</el-button>
			  <el-button  type="yellow" v-if="scope.row.status==2||scope.row.status==3">{{ forstatus(scope.row)}}</el-button>
			   <el-button  type="green" v-if="scope.row.status==4||scope.row.status==5">{{ forstatus(scope.row)}}</el-button>
			    <el-button  type="black" v-if="scope.row.status==6">{{ forstatus(scope.row)}}</el-button>
		</template>
	</el-table-column>
    <el-table-column prop="repair_user" label="维修人员id"  v-if="false"></el-table-column>
    <el-table-column prop="repair_username" label="维修人员" :show-overflow-tooltip="true"></el-table-column>
    <el-table-column label="优先级"  prop="priority" width="150px">
      <template v-slot="scope">
        <el-switch
            v-model="scope.row.priority"
            active-value="2"
            inactive-value="1"
            active-color="#fe0202"
            inactive-color="#00AA00"
            active-text="加急"
            inactive-text="一般"
            @change="changeSwitch(scope.row)"
            />
        </template>
    </el-table-column>
	 <el-table-column prop="is_del" label="已删除" :formatter="foris_del"></el-table-column>
    <el-table-column fixed="right" label="操作" width="330px" >
      <template v-slot="scope"  >
        <el-button  icon="el-icon-view"    @click="View(scope.row)" type="view" size="mini">查看</el-button>
		<el-button  icon="el-icon-view" v-if="scope.row.rmsUmUm == 1 && scope.row.status == '2' && scope.row.order_type != '4'"  @click="ViewNextPing(scope.row)" type="view" size="mini">评价</el-button>
		<el-button  icon="el-icon-view" v-if="scope.row.status == '2' && scope.row.order_type == '4'"  @click="ViewNext(scope.row)" type="view" size="mini">结单并评价</el-button>
         <el-button  icon="el-icon-setting" v-if="scope.row.order_type != '4' && scope.row.status == '1'" @click="ThreeRepair(scope.row)" type="primary" size="mini">第三方维修</el-button>
		  <el-button  icon="el-icon-setting" v-if="scope.row.order_type == '4' && scope.row.status == '2'" @click="ThreeRepair2(scope.row)" type="primary" size="mini">正常维修</el-button>
		<el-button  icon="el-icon-delete" v-if="scope.row.is_del == '0'" @click="Deleteinfo(scope.row)" type="danger" size="mini">删除</el-button>
		<el-button  v-if="scope.row.status == 1 && scope.row.order_type == 3" @click="unAssignOrderPersonnel(scope.row)" size="mini">取消指派</el-button>
        <el-button  @click="info(scope.row)" type="" size="mini" v-if="ismanager && scope.row.is_del == '0'" >编辑</el-button>
        <el-button  @click="singleinfo(scope.row)" type="" size="mini"  
			v-if="scope.row.is_del == '0' && !scope.row.isOverTime && (scope.row.status==2 || scope.row.status==3 || scope.row.status==4)">故障返修</el-button>
		<el-button  @click="tipbtn(scope.row)" type="" size="mini"
			v-if="scope.row.is_del == '0' &&  scope.row.isOverTime && (scope.row.status==2 || scope.row.status==3 || scope.row.status==4)">
			已过故障返修有效期</el-button>
		
      </template>
    </el-table-column>
  </el-table>
  <el-config-provider :locale="locale">
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange" background
        :current-page="currentPage"
        :page-size="pagesize"
        layout="total,sizes,prev,pager,next,jmper"
        :total="total">
    </el-pagination>
  </el-config-provider>
  
  <rep_repairs_view_next_ping @closerepairsView="closerepairsView" v-if="repairsViewNextPingDialogVisible"></rep_repairs_view_next_ping>
  <rep_repairs_view_next @closerepairsView="closerepairsView"  v-if="repairsViewNextDialogVisible"></rep_repairs_view_next>
   <rep_repairs_view @closerepairsView="closerepairsView"  v-if="repairsViewDialogVisible"></rep_repairs_view>
  <rep_repairs_add @closerepairsView="closerepairsAdd"  v-if="repairsAddDialogVisible"></rep_repairs_add>
  <rep_repairs_assign @closerepairsAssign="closerepairsAssign"  v-if="repairsAssignDialogVisible"></rep_repairs_assign>
  <rep_repairs_baja @closerepairsBaja="closerepairsBaja"  v-if="repairsBajanDialogVisible"></rep_repairs_baja>
</template>
<script>
import rep_repairs_view_next_ping from './rep_repairs_view_next_ping.vue';
import rep_repairs_view_next from './rep_repairs_view_next.vue';
import rep_repairs_view from './rep_repairs_view.vue';
import rep_repairs_add from './rep_repairs_add.vue';
import rep_repairs_assign from './rep_repairs_assign.vue'
import rep_repairs_baja from './rep_repairs_baja.vue'
//引入vue方法
import { ElConfigProvider } from 'element-plus';
//中文包
import zhCn from "element-plus/lib/locale/lang/zh-cn";
export default {

  name: 'ZhProvider',

  setup(){
    let locale = zhCn
    return{
      locale
    }
  },
  /*启动后触发该方法*/
  created() {
    this.handleClickQuery();
    this.getplotname();

  },
  /*方法主体*/
  methods: {
	ThreeRepair:function(row){
		this.$confirm('是否确认将此工单转为第三方维修。', '提示', {
		  confirmButtonText: '确定',
		  cancelButtonText: '取消',
		  type: 'warning'
		}).then(() => {
			var self = this;
			var data = {};
			data.cmd = "thirdRepairsInfo";
			data.id=row.id,		 
			this.$sknet.postJson(this.$skconf("met.surl"), data, function(data){
					 this.handleClickQuery();
			}, function(data) { }, self);
		}).catch(() => {});
	},
	ThreeRepair2:function(row){
		this.$confirm('是否确认将此工单从第三方维修转为正常维修。', '提示', {
		  confirmButtonText: '确定',
		  cancelButtonText: '取消',
		  type: 'warning'
		}).then(() => {
			var self = this;
			var data = {};
			data.cmd = "thirdRepairsInfo2";
			data.id = row.id,		 
			this.$sknet.postJson(this.$skconf("met.surl"), data, function(data){
					 this.handleClickQuery();
			}, function(data) { }, self);
		}).catch(() => {});
	},
    changeSwitch:function(row){
      var self = this;
      var data = {};
      data.cmd = "priorityRepairsInfo";
      data.id=row.id,
      data.priority=row.priority,
      this.$sknet.postJson(this.$skconf("met.surl"), data, function(data){
         this.handleClickQuery();
      }, function(data) {
      }, self);
    },

    handleClickQuery:function(data){
		var self = this;
      this.dataList = [];
      var req = {};
     req.cmd = "queryRepairs";
     req.start=(this.currentPage-1) * this.pagesize;
     req.count=this.pagesize;
     req.plot_id =this.article;    //所属项目
     req.status =this.status;      //会员状态
     req.selecttime=this.selecttime; //查询时间
     req.synthesize_params=this.tableDataName;   //综合查询
	 req.order_type=this.order_type;   //工单类型查询
	   req.is_del = this.is_del_info;
	   req.id=this.bsid;   //综合查询
      this.$sknet.postJson(this.$skconf("brum.surl"), req, function(res) {
        if(res.datas.ismanager>=1){
          this.ismanager=true;
        }
       
		if(res.datas.items && res.datas.items.length > 0){
			this.dataList= this.formatItems(res.datas.items);
		}
        this.total=res.datas.totalCount;
      }, null, this);
    },
	formatItems:function(items){
		var res = [];
		var tmp = {};
		var bd = 0;
		for(var i = 0; i <  items.length; i++){
			tmp = items[i];
			tmp.isOverTime = false;
			if(tmp.finish_time && tmp.finish_time != ""){
				bd = this.getDaysBetween(tmp.finish_time);
				if(bd >= 15){
					tmp.isOverTime = true;
				}
			}
			res.push(tmp);
		}
		return res;
	},
	getDaysBetween:function(dateString1){
	    var  startDate = Date.parse(dateString1);
	    var  endDate = new Date();
	    if (startDate>endDate){
	        return 0;
	    }
	    if (startDate==endDate){
	        return 0;
	    }
	    var days=(endDate - startDate)/(1*24*60*60*1000);
	    return  days;
	},

    //条件查询的方法
    queryrepairs:function(){
      this.dataList = [];
      var req = {};
      req.cmd = "queryRepairs";
      req.start=(this.currentPage-1) * this.pagesize;
      req.count=this.pagesize;
      req.plot_id =this.article;    //所属项目
      req.status =this.status;      //会员状态
      req.selecttime=this.selecttime; //查询时间
	  req.is_del = this.is_del_info;
      req.synthesize_params=this.tableDataName;   //综合查询
	  	 req.order_type=this.order_type;   //工单类型查询
	    req.id=this.bsid;   //综合查询
      this.$sknet.postJson(this.$skconf("met.surl"), req, function(res) {
        console.log(res);
        //this.dataList=res.datas.items;
		if(res.datas.items && res.datas.items.length > 0){
			this.dataList= this.formatItems(res.datas.items);
		}
        this.total=res.datas.totalCount;
      }, null, this);
    },
    //等级评级查询
    querygrade:function(){
      this.dataList = [];
      var req = {};
      req.cmd = "queryRepairs";
      req.start=(this.currentPage-1) * this.pagesize;
      req.count=this.pagesize;

      req.status =3;      //会员状态
      req.selecttime=this.selecttime; //查询时间
	  req.id=this.bsid;   //综合查询
      this.$sknet.postJson(this.$skconf("met.surl"), req, function(res) {
        console.log(res);
        //this.dataList=res.datas.items;
		if(res.datas.items && res.datas.items.length > 0){
			this.dataList= this.formatItems(res.datas.items);
		}
		
        this.total=res.datas.totalCount;
      }, null, this);
    },
    //导出excel
    exportExcel:function(){
      this.dataList = [];
      var req = {};
      req.cmd = "orderPersonnelExcel";
      req.plot_id =this.article;    //所属项目
      req.status =this.status;      //会员状态
	  req.id=this.bsid;   //综合查询
      if(this.selecttime != null && this.selecttime != undefined && this.selecttime != ''){
		 req.StartTime = this.selecttime[0] || ""; //查询时间
		 req.endTime = this.selecttime[1] || ""; //查询时间
	  }
      req.synthesize_params=this.tableDataName;   //综合查询
      this.$sknet.download(this.$skconf("met.surl"), req)
      this.handleClickQuery();
    },
    //查询所有项目的方法
    getplotname:function(){
      var self = this;
      self.options;
      var redata = {};
      redata.cmd = "getPmsName";
      redata.id=this.ids;
      this.$sknet.postJson(this.$skconf("met.surl"), redata, function(datas){
        this.optionsype=datas.datas.items;
      }, function(data) {
        self.$message(data.reason);
      }, self);
    },
    //重置方法
    reset:function(){
      this.article='';    //所属项目
      this.status='';      //会员状态
      this.selecttime=[]; //查询时间
      this.tableDataName='';   //综合查询
	  this.bsid='';   //综合查询
      this.handleClickQuery();
    },
    //关闭查看弹出框
    closerepairsView:function(){
      this.id = this.id;
      this.repairsViewDialogVisible = false;
	  this.repairsViewNextDialogVisible = false;
	   this.repairsViewNextPingDialogVisible = false;
      this.handleClickQuery();
    },
    //关闭新增弹出框
    closerepairsAdd:function(){
      this.id = this.id;
      this.repairsAddDialogVisible = false;
      this.handleClickQuery();
    },
    //关闭指派工单弹出框
    closerepairsAssign:function(){

      this.id = this.id;
      this.repairsAssignDialogVisible  = false;
      this.handleClickQuery();
    },
    closerepairsBaja:function(){

      this.id = this.id;
      this.repairsBajanDialogVisible  = false;
      this.handleClickQuery();
    },
    /*跳转查看页面的方法*/
    View: function(item) {
      this.id = item.id+"";
      this.repairsViewDialogVisible = true;
    },
	ViewNext: function(item) {
	  this.id = item.id+"";
	  this.repairsViewNextDialogVisible = true;
	},
	ViewNextPing: function(item) {
	  this.id = item.id+"";
	  this.repairsViewNextPingDialogVisible = true;
	},
    /*跳转编辑页面的方法*/
    info: function(item) {
      this.id = item.id+"";
      this.status = item.status+"";
      this.order_type = item.order_type+"";

      this.repairsBajanDialogVisible = true;
    },
    /*跳转新增页面的方法*/
    addition:function(){
      this.repairsAddDialogVisible = true;
    },
	unAssignOrderPersonnel:function(item){
		var self = this;
		var data = {};
		data.cmd = "unAssignOrderPersonnel";
		data.order_id = item.id,
		this.$sknet.postJson(this.$skconf("met.surl"), data, function(data){
		  self.$message("取消成功");
		  this.handleClickQuery();
		}, function(data) {
		}, self);
	},
    /*跳转指派工单页面的方法*/
    assign:function(){

      var req={};
      if(this.paramsid==null||this.paramsid==''){

        this.$message({
          message: '请选择一条信息',
          type: 'warning'
        });
		return;
      }
	  
	  if(this.selOrderStatus != 1){
		  this.$message({
		    message: '只能选未完成的订单',
		    type: 'warning'
		  });
		  return;
	  }
	  

      else  if(this.work_type==1||this.work_type==3){
        this.orderid=this.paramsid;
        this.repairsAssignDialogVisible = true;
      }
      else {
        this.$message({
          message: '只能选择等待抢单的订单',
          type: 'warning'
        });
      }

    },
    /*删除的方法*/
    Deleteinfo:function(item){
      this.$confirm('是否确认删除。', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.updeteclass(item);
      }).catch(() => {});
    },
    updeteclass:function(item){
          var self = this;
          var data = {};
          data.cmd = "delRepairsById";
          data.id=item.id,
          data.is_del=1,
          this.$sknet.postJson(this.$skconf("met.surl"), data, function(data){
            self.$message("删除成功");
            this.handleClickQuery();
          }, function(data) {
          }, self);
    },
    /*分页代码*/
    handleSizeChange: function (size) {
      this.pagesize = size;
      console.log(this.pagesize)  //每页下拉显示数据

    },
    handleCurrentChange: function(currentPage){
      this.currentPage = currentPage;
      this.handleClickQuery();
      console.log(this.currentPage)  //点击第几页
    },

	tipbtn:function(item){
		this.$message({
		  message: '该条报修完成时间：'+ item.finish_time +'，已过返修有效期（15天内），无法故障返修',
		  type: 'warning'
		});
		return;
	},
    singleinfo:function(item){
		
      this.$confirm('是否确认故障返修。', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
			this.$prompt('返修原因：', '提示', {
			inputPattern: /\S/,
			inputErrorMessage: '返修原因不能为空',
			cancelButtonText: '取消',
			confirmButtonText: '确定',
			inputPlaceholder: '请输入您的返修原因',
		  }).then(({ value }) => {
			this.singleclass(item,value);
		  });
      }).catch(() => {});
    },

    singleclass:function(item,fix_reason){
      var self = this;
      var data = {};
      if(item.status==5){
        this.$message({
          message: '该条信息无法故障返修',
          type: 'warning'
        });
      }else{
			data.cmd = "singleRepairsInfo";
			data.id=item.id;
			data.fix_reason=fix_reason;
			this.$sknet.postJson(this.$skconf("met.surl"), data, function(data){
				self.$message("返修成功");
				this.handleClickQuery();
			  }, function(data) {
			}, self);
      }
    },
    /*取消工单的接口*/
    cancel:function(){

      if(this.work_type!=1){
        this.$message({
          message: '只能选择等待抢单的订单',
          type: 'warning'
        });
        return ;
      }
      var self = this;
      var data = {};
      data.cmd = "cancelWorkOrder";
      data.id=this.paramsid;
          if(this.paramsid==null||this.paramsid==''){
            this.$message({
              message: '请选择一条信息',
              type: 'warning'
            });
          }
          this.$sknet.postJson(this.$skconf("met.surl"), data, function(data){

            self.$message("取消成功");
            this.handleClickQuery();
          }, function(data) {
          }, self);
    },
    // 根据数字返回文字
    formode:function(row,colum){
      const state={
        1:'有偿',
        2:'无偿',
      }
      return  state[row.mode];
    },
	foris_del:function(row,colum){
	  const state={
	    1:'是',
	    0:'否',
	  }
	  return  state[row.is_del];
	},
    forordertype:function(row,colum){
      const state={
        1:'等待抢单',
        2:'前台抢单',
        3:'后台分配',
      }
      return  state[row.order_type];
    },
	setCreateTime:function(row,colum){
		return row.create_time.substr(0,19);
	},
    forstatus:function(row,colum){
      const state={
        1:'未完成',
        2:'待评价',
        3:'待评级',
        4:'已完成',
        5:'已返修',
        6:'已取消',
      }
      return  state[row.status];
    },
	TableRowStyle({ row, rowIndex }) {
	
	let rowBackground = {};
	/* if (row.status == 1) {
	rowBackground = {background:"#bbbbbb",'font-weight': '800'};
	}
	
	if (row.status == 2 || row.status == 3) {
	rowBackground =  {background:"#efbd80",'font-weight': '800'};
	}
	
	
	if (row.status == 5 || row.status == 4) {
	rowBackground =  {background:"#9dc77d",'font-weight': '800'};
	}
	
	
	if (row.status == 6) {
	rowBackground =  {background:"#5a5959",'font-weight': '800'};
	} */
	
	
	if (row.priority>1) {
	rowBackground = {background:"#e3acac",'font-weight': '800'};
	}
	
	return rowBackground;
	},
    // 单选方法
    handleSelectionChange:function(selection) {

     if(selection.length ==0){    //取消操作
        this.paramsid='';
        this.paramsrepair='';
        this.calloff='';
		this.work_type='';
		this.selOrderStatus = -1;
      }
      else if(selection.length ==1){    //选择操作

        this.work_type=selection[0].order_type;
        this.paramsid=selection[0].id;
        this.paramsrepair=selection[0].repair_user;
        this.calloff=selection[0].status;
		this.selOrderStatus = selection[0].status;
      }
      else if(selection.length >1){
        this.$refs.multipleTable.clearSelection();
        this.$refs.multipleTable.toggleRowSelection(selection.pop());
      }
    },

  },
  data() {
    let self = this;
    return{
		bsid:'',
      dataList:'',
      tableDataName:'',         //综合查询的字段
      repairsViewDialogVisible:false, //查看页面弹出的判断标识
      repairsAddDialogVisible:false,  //新增页面的判断的标识
      repairsAssignDialogVisible:false, //指派工单的判断标识
      repairsBajanDialogVisible:false,//经理权限界面的标识
	  repairsViewNextDialogVisible:false,
	  repairsViewNextPingDialogVisible:false,
      total:0,          //总页数
      pagesize:10,        //每页数量
      currentPage:1,    //初始页
      checkedGh: [],    //复选框默认值
      paramsid:'',    //单选框指向的id
      paramsrepair:'',//单选行的施工人员id
	  is_del_info:'0',
	  is_dels:[{
          value:'',
          label:'全部',
        },
        {
          value:'0',
          label:'正常',
        },
        {
          value:'1',
          label:'已删除',
        }],
      statuss:[         //报修状态

        {
          value:'1',
          label:'未完成',
        },
        {
          value:'2',
          label:'待评价',
        },
        {
          value:'3',
          label:'待评级',
        },
        {
          value:'4',
          label:'已完成',
        },
        {
          value:'5',
          label:'已追单',
        },
        {
          value:'6',
          label:'已取消',
        },
      ],
	  selOrderStatus: -1, //选择的订单状态
      article:'',     //所属项目选项
      work_type:'',
      optionsype:'',  //所属项目下拉
      status:'',      //报修状态选项
      selecttime:[],   //时间查询的字段
      orderid:"",
      calloff:'',
      dialog_visible:false,
      ismanager:false,
	  order_types:[
	    {
	      value:'1',
	      label:'等待抢单',
	    },
		{
		  value:'2',
		  label:'前台抢单',
		},
		{
		  value:'3',
		  label:'后台分配',
		},
		{
		  value:'4',
		  label:'其他单位',
		}
	  ],
	   order_type:null,
    }
  },
  components:{
    rep_repairs_view,
    rep_repairs_add,
    rep_repairs_assign,
    rep_repairs_baja,
    [ElConfigProvider.name]: ElConfigProvider,rep_repairs_view_next,rep_repairs_view_next_ping
  }

}


</script>

<style scoped>

.el-button--grey { 
  background-color: #bbbbbb;
  border-color: #bbbbbb !important;
  color: white;
  padding: 10px;
}

.el-button--yellow { 
  background-color:  #efbd80  !important;
  color: white;
  border-color: #efbd80 ;
  padding: 10px;
}

.el-button--green { 
  background-color:  #9dc77d  !important;
  color: white;
  border-color: #9dc77d ;
  padding: 10px;
}

.el-button--black { 
  background-color:  #5a5959 !important;
  color: white;
  border-color: #5a5959;
  padding: 10px;
}

</style>